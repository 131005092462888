jQuery(document).ready(function() {
    var b = "nostojs";
    window[b] = window[b] || function (a) {
        (window[b].q = window[b].q || []).push(a)
    }, window[b].l = new Date;
    var c = function (d, e) {
        e = e || {}, window[b].o = e;
        var f = document.location.protocol,
            g = ["https:" === f ? f : "http:", "//", e.host || "connect.nosto.com", e.path || "/include/", d].join("");
        var script = document.createElement("script");
        script.src = g;
        script.defer = true;
        document.head.appendChild(script);
    };
    window[b].init = c
})